import Achievement from '@/types/achievements'

export const achievements: Record<string, Achievement> = {
    a01: {
        sid: 'a01',
        label: 'This is kinda fun...',
        description: 'You\'ve hand-cooked your first batch of meth',
        property: 'stats.cookedByClicks',
        required: 1,
        unlocked: false,
        value: 1,
        group: 10,
        minTime: 1,
        hidden: false,
    },
    a02: {
        sid: 'a02',
        label: 'I see how this works',
        description: 'You\'ve hand-cooked 100 batches of meth',
        property: 'stats.cookedByClicks',
        required: 100,
        unlocked: false,
        value: 2,
        group: 11,
        minTime: 1,
        hidden: false,
    },
    a03: {
        sid: 'a03',
        label: 'Click apprentice',
        description: 'You\'ve hand-cooked 1,000 batches of meth',
        property: 'stats.cookedByClicks',
        required: 1_000,
        unlocked: false,
        value: 3,
        group: 12,
        minTime: 1,
        hidden: false,
    },
    a04: {
        sid: 'a04',
        label: 'Click magician',
        description: 'You\'ve hand-cooked 100,000 batches of meth',
        property: 'stats.cookedByClicks',
        required: 100_000,
        unlocked: false,
        value: 4,
        group: 13,
        minTime: 1,
        hidden: false,
    },
    a05: {
        sid: 'a05',
        label: 'Clickity-splickity',
        description: 'You\'ve hand-cooked 1,000,000 batches of meth',
        property: 'stats.cookedByClicks',
        required: 1_000_000,
        unlocked: false,
        value: 5,
        group: 14,
        minTime: 1,
        hidden: false,
    },
    a06: {
        sid: 'a06',
        label: 'I AM THE ONE WHO CLICKS',
        description: 'YOU are to be feared. You\'ve hand-cooked 100,000,000 batches of meth!',
        property: 'stats.cookedByClicks',
        required: 100_000_000,
        unlocked: false,
        value: 6,
        group: 15,
        minTime: 1,
        hidden: false,
    },
    a07: {
        sid: 'a07',
        label: 'In the meth business',
        description: 'You\'ve earned your first $1,000',
        property: 'stats.totalCash',
        required: 1_000,
        unlocked: false,
        value: 1,
        group: 20,
        minTime: 1,
        hidden: false,
    },
    a08: {
        sid: 'a08',
        label: 'In the money business',
        description: 'You\'ve earned your first $1,000,000',
        property: 'stats.totalCash',
        required: 1_000_000,
        unlocked: false,
        value: 2,
        group: 21,
        minTime: 1,
        hidden: false,
    },
    a09: {
        sid: 'a09',
        label: 'Billion with a B',
        description: 'You\'ve earned your first $1,000,000,000',
        property: 'stats.totalCash',
        required: 1_000_000_000,
        unlocked: false,
        value: 3,
        group: 22,
        minTime: 1,
        hidden: false,
    },
    a10: {
        sid: 'a10',
        label: 'In the cartel business',
        description: 'You\'ve earned your first $1 trillion',
        property: 'stats.totalCash',
        required: 1_000_000_000_000,
        unlocked: false,
        value: 4,
        group: 23,
        minTime: 1,
        hidden: false,
    },
    a11: {
        sid: 'a11',
        label: 'In the empire business',
        description: 'You\'ve earned $1 quadrillion dollars!',
        property: 'stats.totalCash',
        required: 1_000_000_000_000_000,
        unlocked: false,
        value: 5,
        group: 24,
        minTime: 1,
        hidden: false,
    },
    a12: {
        sid: 'a12',
        label: 'Cookin\' with C.H.U.D.',
        description: 'You\'ve built a Subterranean Complex',
        property: 'cookers.c10.amount',
        required: 1,
        unlocked: false,
        value: 1,
        group: 200,
        minTime: 1,
        hidden: false,
    },
    a13: {
        sid: 'a13',
        label: 'I am the law!',
        description: 'An entire City Police Force is on your payroll',
        property: 'sellers.s09.amount',
        required: 1,
        unlocked: false,
        value: 1,
        group: 210,
        minTime: 1,
        hidden: false,
    },
    a14: {
        sid: 'a14',
        label: 'Science, bitch!',
        description: 'You\'ve acquired a laboratory',
        property: 'cookers.c06.amount',
        required: 1,
        unlocked: false,
        value: 1,
        group: 220,
        minTime: 1,
        hidden: false,
    },
    a15: {
        sid: 'a15',
        label: 'One small step for meth kind',
        description: 'You\'ve built a Moon Lab',
        property: 'cookers.c12.amount',
        required: 1,
        unlocked: false,
        value: 1,
        group: 227,
        minTime: 1,
        hidden: false,
    },
    a16: {
        sid: 'a16',
        label: 'The meth is strong with you',
        description: 'You\'ve built a Meth Star',
        property: 'cookers.c13.amount',
        required: 1,
        unlocked: false,
        value: 1,
        group: 227,
        minTime: 1,
        hidden: false,
    },
    a17: {
        sid: 'a17',
        label: 'On the inside',
        description: 'A DEA Mole is now in your pocket',
        property: 'sellers.s07.amount',
        required: 1,
        unlocked: false,
        value: 1,
        group: 227,
        minTime: 1,
        hidden: false,
    },
    a18: {
        sid: 'a18',
        label: 'Frozen Bananas',
        description: 'There\'s always money in the banana stand',
        property: 'banks.b03.amount',
        required: 1,
        unlocked: false,
        value: 1,
        group: 250,
        minTime: 1,
        hidden: false,
    },
    a19: {
        sid: 'a19',
        label: 'Government Shutdown',
        description: 'You\'ve bought the entire senate (100 Crooked Senators)!',
        property: 'sellers.s10.amount',
        required: 100,
        unlocked: false,
        value: 1,
        group: 228,
        minTime: 1,
        hidden: false,
    },
    a20: {
        sid: 'a20',
        label: 'McLaunder\'s',
        description: 'You\'ve bought a franchise!',
        property: 'banks.b10.amount',
        required: 1,
        unlocked: false,
        value: 1,
        group: 229,
        minTime: 1,
        hidden: false,
    },
    a21: {
        sid: 'a21',
        label: 'Laundering Better Worlds',
        description: 'You\'ve bought a Space Corp!',
        property: 'banks.b13.amount',
        required: 1,
        unlocked: false,
        value: 1,
        group: 240,
        minTime: 1,
        hidden: false,
    },
    a22: {
        sid: 'a22',
        label: 'A Minute of Your Time',
        description: 'You\'ve managed a meth lab for 1 minute',
        property: 'stats.timePlayed',
        required: 60,
        unlocked: false,
        value: 1,
        group: 301,
        minTime: 1,
        hidden: false,
    },
    a23: {
        sid: 'a23',
        label: 'Five Minutes of Fame',
        description: 'You\'ve managed a meth lab for 5 minutes',
        property: 'stats.timePlayed',
        required: 300,
        unlocked: false,
        value: 2,
        group: 302,
        minTime: 1,
        hidden: false,
    },
    a24: {
        sid: 'a24',
        label: 'First Lab Hour',
        description: 'You\'ve managed a meth lab for an hour',
        property: 'stats.timePlayed',
        required: 3_600,
        unlocked: false,
        value: 3,
        group: 303,
        minTime: 1,
        hidden: false,
    },
    a25: {
        sid: 'a25',
        label: 'Half a day down the drain',
        description: 'You\'ve managed a meth empire for 12 hours',
        property: 'stats.timePlayed',
        required: 43_200,
        unlocked: false,
        value: 5,
        group: 304,
        minTime: 1,
        hidden: false,
    },
    a26: {
        sid: 'a26',
        label: 'Addict',
        description: 'You\'ve managed a meth empire for an entire day',
        property: 'stats.timePlayed',
        required: 86_400,
        unlocked: false,
        value: 10,
        group: 305,
        minTime: 1,
        hidden: false,
    },
    a27: {
        sid: 'a27',
        label: 'Junkie',
        description: 'You\'ve managed a meth empire for an entire week!',
        property: 'stats.timePlayed',
        required: 86_400 * 5,
        unlocked: false,
        value: 10,
        group: 306,
        minTime: 1,
        hidden: false,
    },
    a28: {
        sid: 'a28',
        label: 'Burnout',
        description: 'You\'ve managed a meth empire for a month! Dayum!',
        property: 'stats.timePlayed',
        required: 86_400 * 30,
        unlocked: false,
        value: 10,
        group: 307,
        minTime: 1,
        hidden: false,
    },
    a29: {
        sid: 'a29',
        label: 'Kardashian',
        description: 'You\'ve spent your first $1,000,000!',
        property: 'stats.totalSpent',
        required: 1_000_000,
        unlocked: false,
        value: 1,
        group: 315,
        minTime: 1,
        hidden: false,
    },
    a30: {
        sid: 'a30',
        label: 'Kanye',
        description: 'You\'ve spent your first $1,000,000,000!',
        property: 'stats.totalSpent',
        required: 1_000_000_000,
        unlocked: false,
        value: 5,
        group: 316,
        minTime: 1,
        hidden: false,
    },
    a31: {
        sid: 'a31',
        label: 'The Fed',
        description: 'You\'ve spent your first $1,000,000,000,000!',
        property: 'stats.totalSpent',
        required: 1_000_000_000_000,
        unlocked: false,
        value: 10,
        group: 317,
        minTime: 1,
        hidden: false,
    },
    a32: {
        sid: 'a32',
        label: 'THE High Roller',
        description: 'You\'ve spent your first $1 Quadrillion!',
        property: 'stats.totalSpent',
        required: 1_000_000_000_000_000,
        unlocked: false,
        value: 15,
        group: 318,
        minTime: 1,
        hidden: false,
    },
    a33: {
        sid: 'a33',
        label: 'Fortune 1',
        description: 'You\'ve spent your first $1 Quintillion!',
        property: 'stats.totalSpent',
        required: 1_000_000_000_000_000_000,
        unlocked: false,
        value: 25,
        group: 319,
        minTime: 1,
        hidden: false,
    },
    a101: {
        sid: 'a101',
        label: 'Counterfeiter',
        description: 'You\'ve hacked in some cash',
        property: 'stats.cheatedCash',
        required: 1,
        unlocked: false,
        value: 1,
        group: 230,
        minTime: 1,
        hidden: true,
    },
    a102: {
        sid: 'a102',
        label: 'Meth from nowhere',
        description: 'You\'ve hacked in some batches-o-meth',
        property: 'stats.cheatedBatches',
        required: 1,
        unlocked: false,
        value: 1,
        group: 240,
        minTime: 1,
        hidden: true,
    },
}
