import Cooker from '@/types/cookers'

export const cookers: Record<string, Cooker> = {
    c01: {
        sid: 'c01',
        label: 'Storage Shed',
        description: 'A cheap shed with electricity',
        amount: 0,
        risk: 0.05,
        rps: 0.2,
        baseCost: 20,
        cost: 20,
        unlockRps: 0,
        unlocked: true,
    },
    c02: {
        sid: 'c02',
        label: 'Used RV',
        description: 'A low cost RV, perfect for remote cooking',
        amount: 0,
        risk: 0.005,
        rps: 1,
        baseCost: 210,
        cost: 210,
        unlockRps: 0.2,
        unlocked: false,
    },
    c03: {
        sid: 'c03',
        label: 'Abandoned Trailer',
        description: 'An abandoned trailer in an area heavily neglected by the police',
        amount: 0,
        risk: 0.01,
        rps: 5,
        baseCost: 1_000,
        cost: 1_000,
        unlockRps: 1.2,
        unlocked: false,
    },
    c04: {
        sid: 'c04',
        label: 'Small House',
        description: 'A small house in a bad neighborhood',
        amount: 0,
        risk: 0.05,
        rps: 25,
        baseCost: 5_000,
        cost: 5_000,
        unlockRps: 6,
        unlocked: false,
    },
    c05: {
        sid: 'c05',
        label: 'Abandoned Warehouse',
        description: 'A large abandoned warehouse with vaulted ceilings',
        amount: 0,
        risk: 0.05,
        rps: 100,
        baseCost: 25_000,
        cost: 25_000,
        unlockRps: 50,
        unlocked: false,
    },
    c06: {
        sid: 'c06',
        label: 'Laboratory',
        description: 'An above ground laboratory outfitted for meth production',
        amount: 0,
        risk: 0.1,
        rps: 500,
        baseCost: 125_000,
        cost: 125_000,
        unlockRps: 250,
        unlocked: false,
    },
    c07: {
        sid: 'c07',
        label: 'Underground Laboratory',
        description: 'An massive hidden laboratory for your discreet cooking needs',
        amount: 0,
        risk: 0.005,
        rps: 2000,
        baseCost: 6_250_000,
        cost: 6_250_000,
        unlockRps: 1_000,
        unlocked: false,
    },
    c08: {
        sid: 'c08',
        label: 'Meth-o-matic 9000',
        description: 'The latest and greatest in laboratory technology, converted for meth production',
        amount: 0,
        risk: 0.1,
        rps: 13_000,
        baseCost: 35_000_000,
        cost: 35_000_000,
        unlockRps: 6_500,
        unlocked: false,
    },
    c09: {
        sid: 'c09',
        label: 'Meth-o-matic 9000S',
        description: 'Just like Meth-o-matic, but features voice activated control',
        amount: 0,
        risk: 0.1,
        rps: 14_000,
        baseCost: 40_000_000,
        cost: 40_000_000,
        unlockRps: 8_000,
        unlocked: false,
    },
    c10: {
        sid: 'c10',
        label: 'Subterranean Complex',
        description: 'A sprawling underground complex the size of a small city',
        amount: 0,
        risk: 0.005,
        rps: 50_000,
        baseCost: 505_000_000,
        cost: 505_000_000,
        unlockRps: 25_000,
        unlocked: false,
    },
    c11: {
        sid: 'c11',
        label: 'Island State',
        description: 'Purchase a small island country, and turn it into a meth-based economic superpower',
        amount: 0,
        risk: 0.000_000_01,
        rps: 105_000,
        baseCost: 2_501_000_000,
        cost: 2_501_000_000,
        unlockRps: 50_000,
        unlocked: false,
    },
    c12: {
        sid: 'c12',
        label: 'Moonlab Alpha',
        description: "A massive moon laboratory for cooking meth far away from the DEA's clutches",
        amount: 0,
        risk: 0.000_000_01,
        rps: 1_150_000,
        baseCost: 32_501_000_000,
        cost: 32_501_000_000,
        unlockRps: 150_000,
        unlocked: false,
    },
    c13: {
        sid: 'c13',
        label: 'Meth Star',
        description: "That's no moon... it's a man made super lab!  With tractor beams.",
        amount: 0,
        risk: 0.000_000_001,
        rps: 11_205_000,
        baseCost: 1_632_501_000_000,
        cost: 1_632_501_000_000,
        unlockRps: 1_300_000,
        unlocked: false,
    },
    c14: {
        sid: 'c14',
        label: 'Industrial Complex',
        description: "A massive industrial complex with thousands of cooks. Cautious? Nope! Effective? You're Goddamn right",
        amount: 0,
        risk: 0.55,
        rps: 55_205_000,
        baseCost: 41_032_501_000_000,
        cost: 41_032_501_000_000,
        unlockRps: 13_000_000,
        unlocked: false,
    },
    c15: {
        sid: 'c15',
        label: 'Heisenbelt',
        description: 'A series of labs on the outer edges of the solar system that convert asteroids into pure crystal',
        amount: 0,
        risk: 0.000_001,
        rps: 492_005_000,
        baseCost: 410_302_501_000_000,
        cost: 410_302_501_000_000,
        unlockRps: 53_000_000,
        unlocked: false,
    },
    c16: {
        sid: 'c16',
        label: 'Planetary Meth Replicator',
        description: "Convert all of a planet's matter into pure crystal",
        amount: 0,
        risk: 0.000_001,
        rps: 1_590_000_000,
        baseCost: 1_910_000_000_000_000,
        cost: 1_910_000_000_000_000,
        unlockRps: 110_000_000,
        unlocked: false,
    },
    c17: {
        sid: 'c17',
        label: 'Portal to The Crystalverse',
        description: 'Attempt to open a portal to another universe full of Cookies, err... Crystal Meth',
        amount: 0,
        risk: 0.1,
        rps: 21_590_000_000,
        baseCost: 21_910_000_000_000_000,
        cost: 21_910_000_000_000_000,
        unlockRps: 2_090_000_000,
        unlocked: false,
    },
}
