import Bank from '@/types/banks'

export const banks: Record<string, Bank> = {
    b01: {
        sid: 'b01',
        label: 'Lemonade Stand',
        description: 'Launder minimal cash through a lemonade stand',
        amount: 0,
        rps: 1,
        unlockRps: 1,
        baseCost: 200,
        cost: 200,
        unlocked: false,
    },
    b02: {
        sid: 'b02',
        label: 'Nail Salon',
        description: 'Purchase a nail salon to launder a small amount of cash',
        amount: 0,
        rps: 12,
        unlockRps: 5,
        baseCost: 8_000,
        cost: 8_000,
        unlocked: false,
    },
    b03: {
        sid: 'b03',
        label: 'Banana Stand',
        description: 'Invest in a banana stand for your laundering pleasure',
        amount: 0,
        rps: 120,
        unlockRps: 12,
        baseCost: 100_000,
        cost: 100_000,
        unlocked: false,
    },
    b04: {
        sid: 'b04',
        label: 'Chicken Restaurant',
        description: 'Invest in a fried chicken restaurant to safely launder a fair amount of cash',
        amount: 0,
        rps: 1_500,
        unlockRps: 150,
        baseCost: 1_000_000,
        cost: 1_000_000,
        unlocked: false,
    },
    b05: {
        sid: 'b05',
        label: 'Laser Tag Theme Park',
        description: 'Launder money through laser tag!',
        amount: 0,
        rps: 16_000,
        unlockRps: 500,
        baseCost: 10_000_000,
        cost: 10_000_000,
        unlocked: false,
    },
    b06: {
        sid: 'b06',
        label: 'Car Wash',
        description: 'Launder cash through an overpriced car wash',
        amount: 0,
        rps: 220_000,
        unlockRps: 5_000,
        baseCost: 100_000_000,
        cost: 100_000_000,
        unlocked: false,
    },
    b07: {
        sid: 'b07',
        label: 'Online Donations',
        description: 'Launder cash through an online donation network',
        amount: 0,
        rps: 5_200_000,
        unlockRps: 50_000,
        baseCost: 500_000_000,
        cost: 500_000_000,
        unlocked: false,
    },
    b08: {
        sid: 'b08',
        label: 'Offshore Account',
        description: 'Launder cash using an offshore finance account',
        amount: 0,
        rps: 60_000_000,
        unlockRps: 500_000,
        baseCost: 4_500_000_000,
        cost: 4_500_000_000,
        unlocked: false,
    },
    b09: {
        sid: 'b09',
        label: 'NYME',
        description: 'Launder high volumes of cash through stocks and bonds on the New York Meth Exchange ',
        amount: 0,
        rps: 750_000_000,
        unlockRps: 5_000_000,
        baseCost: 50_500_000_000,
        cost: 50_500_000_000,
        unlocked: false,
    },
    b10: {
        sid: 'b10',
        label: 'Food Franchise',
        description: 'Why launder through a restaurant, when you can launder through an entire franchise? ',
        amount: 0,
        rps: 4_550_000_000,
        unlockRps: 15_000_000,
        baseCost: 150_500_000_000,
        cost: 150_500_000_000,
        unlocked: false,
    },
    b11: {
        sid: 'b11',
        label: 'Space Cantina',
        description: 'Launder your cash on an intergalactic scale with the Space Cantina',
        amount: 0,
        rps: 14_550_000_000,
        unlockRps: 30_000_000,
        baseCost: 750_500_000_000,
        cost: 750_500_000_000,
        unlocked: false,
    },
    b12: {
        sid: 'b12',
        label: 'Space Resort',
        description: 'Launder your cash through a low gravity spa and resort',
        amount: 0,
        rps: 200_000_000_000,
        unlockRps: 50_000_000,
        baseCost: 2_500_500_000_000,
        cost: 2_500_500_000_000,
        unlocked: false,
    },
    b13: {
        sid: 'b13',
        label: 'Space Corp',
        description: 'Launder cash through a shady conglomerate that deals in planetary colonisation, asteroid mining, and other technology, with secret ties to the military',
        amount: 0,
        rps: 1_200_000_000_000,
        unlockRps: 125_000_000,
        baseCost: 10_000_000_000_000,
        cost: 10_000_000_000_000,
        unlocked: false,
    },
    b14: {
        sid: 'b14',
        label: 'The Crystal Methwork',
        description: 'Launder through a popular television network, included in every cable package in the galaxy',
        amount: 0,
        rps: 10_500_000_000_000,
        unlockRps: 525_000_000,
        baseCost: 120_000_000_000_000,
        cost: 120_000_000_000_000,
        unlocked: false,
    },
}
