import Seller from '@/types/sellers'

export const sellers: Record<string, Seller> = {
    s01: {
        sid: 's01',
        label: 'Dealer',
        description: 'A common street thug to sell your goods',
        amount: 0,
        risk: 0.03,
        rps: 0.2,
        baseCost: 20,
        cost: 20,
        unlockRps: 0.5,
        unlocked: true,
    },
    s02: {
        sid: 's02',
        label: 'Drug Mule',
        description: 'Someone to stuff drugs in their rectum and distribute them',
        amount: 0,
        risk: 0.005,
        rps: 1,
        baseCost: 230,
        cost: 230,
        unlockRps: 0.2,
        unlocked: false,
    },
    s03: {
        sid: 's03',
        label: 'Drug Van',
        description: 'A run-down van that actively seeks out customers',
        amount: 0,
        risk: 0.05,
        rps: 8,
        baseCost: 2_300,
        cost: 2_300,
        unlockRps: 1,
        unlocked: false,
    },
    s04: {
        sid: 's04',
        label: 'Sleazy Lawyer',
        description: 'Reduces your risk of getting charged by the DEA, also distributes some product on the side',
        amount: 0,
        risk: -0.05,
        rps: 10,
        baseCost: 5_000,
        cost: 5_000,
        unlockRps: 5,
        unlocked: false,
    },
    s05: {
        sid: 's05',
        label: 'Night Club',
        description: 'Purchase a run down night club and sell your classy product to the patrons',
        amount: 0,
        risk: 0.05,
        rps: 100,
        baseCost: 25_000,
        cost: 25_000,
        unlockRps: 50,
        unlocked: false,
    },
    s06: {
        sid: 's06',
        label: 'Drug Cartel',
        description: 'Drug cartels can move a lot of product, but their services are not free',
        amount: 0,
        risk: 0.10,
        rps: 400,
        baseCost: 125_000,
        cost: 125_000,
        unlockRps: 250,
        unlocked: false,
    },
    s07: {
        sid: 's07',
        label: 'DEA Mole',
        description: 'Hire a DEA informant, which allows for increased and discreet distribution while reducing risk',
        amount: 0,
        risk: -0.05,
        rps: 1_000,
        baseCost: 6_250_000,
        cost: 6_250_000,
        unlockRps: 500,
        unlocked: false,
    },
    s08: {
        sid: 's08',
        label: 'Foreign Diplomat',
        description: 'Hire a foreign diplomat to partner with the best drug pushers in their respective countries and reduce risk',
        amount: 0,
        risk: -0.05,
        rps: 5_000,
        baseCost: 33_000_000,
        cost: 33_000_000,
        unlockRps: 2_500,
        unlocked: false,
    },
    s09: {
        sid: 's09',
        label: 'City Police Force',
        description: 'Pay off an entire city police force. All of them, even the by-the-book detectives, greatly reducing risk',
        amount: 0,
        risk: -0.1,
        rps: 10_500,
        baseCost: 165_000_000,
        cost: 165_000_000,
        unlockRps: 5_000,
        unlocked: false,
    },
    s10: {
        sid: 's10',
        label: 'Crooked Senator',
        description: 'This crooked member of the senate will provide you with some of the best distribution channels as well as help keep the fed outta your biznaz',
        amount: 0,
        risk: -0.1,
        rps: 45_000,
        baseCost: 801_000_000,
        cost: 801_000_000,
        unlockRps: 20_000,
        unlocked: false,
    },
    s11: {
        sid: 's11',
        label: 'Rival Cartel',
        description: 'Pay off a rival cartel, greatly increasing the demand for your product',
        amount: 0,
        risk: 0.25,
        rps: 105_000,
        baseCost: 3_005_000_000,
        cost: 3_005_000_000,
        unlockRps: 60_000,
        unlocked: false,
    },
    s12: {
        sid: 's12',
        label: 'El Presidente',
        description: 'Partner with a dictator of an impoverished country for unchecked meth distribution',
        amount: 0,
        risk: -0.05,
        rps: 1_150_000,
        baseCost: 32_501_000_000,
        cost: 32_501_000_000,
        unlockRps: 160_000,
        unlocked: false,
    },
    s13: {
        sid: 's13',
        label: 'Space Mules',
        description: "Space mules ain't your daddy's drug mule. They supply extra terrestrial beings with a steady stream of your sweet sweet crystal",
        amount: 0,
        risk: 0.000_001,
        rps: 11_205_000,
        baseCost: 1_632_501_000_000,
        cost: 1_632_501_000_000,
        unlockRps: 1_300_000,
        unlocked: false,
    },
    s14: {
        sid: 's14',
        label: 'Meth-Mart',
        description: "The MM franchise is an easy way to distribute product to shoppers, but it's very risky, as you would imagine",
        amount: 0,
        risk: 0.55,
        rps: 55_205_000,
        baseCost: 41_032_501_000_000,
        cost: 41_032_501_000_000,
        unlockRps: 13_000_000,
        unlocked: false,
    },
    s15: {
        sid: 's15',
        label: 'Meth Horizon',
        description: 'According to commander Tony Drake, these high speed shuttles quickly transport product from the far edges of the solar system',
        amount: 0,
        risk: 0.000_01,
        rps: 492_005_000,
        baseCost: 410_325_015_000_000,
        cost: 410_325_015_000_000,
        unlockRps: 53_000_000,
        unlocked: false,
    },
    s16: {
        sid: 's16',
        label: 'Intergalactic Meth Relay',
        description: "Distribute meth to crank-lovin' sentient life from the far reaches of the galaxy",
        amount: 0,
        risk: 0.000_01,
        rps: 1_590_000_000,
        baseCost: 1_910_000_000_000_000,
        cost: 1_910_000_000_000_000,
        unlockRps: 110_000_000,
        unlocked: false,
    },
    s17: {
        sid: 's17',
        label: 'Church of the Crystal',
        description: "Your meth is pure enough that there are some who believe Truth can be seen on the crystal's surface",
        amount: 0,
        risk: 0.000_01,
        rps: 21_590_000_000,
        baseCost: 21_910_000_000_000_000,
        cost: 21_910_000_000_000_000,
        unlockRps: 2_090_000_000,
        unlocked: false,
    },
}
