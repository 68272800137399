import Upgrade, { UpgradeAction } from '@/types/upgrades'

export const upgrades: Record<string, Upgrade> = {
    u01: {
        sid: 'u01',
        label: 'Air Fresheners',
        description: 'With the sweet sent of pine in the air, you can cook an extra batch at a time',
        action: UpgradeAction.Make,
        purchased: false,
        mod: 1,
        cost: 10,
        preReq: null,
    },
    u02: {
        sid: 'u02',
        label: 'Exhaust Fan',
        description: 'You can now cook 5 more batches at a time',
        action: UpgradeAction.Make,
        purchased: false,
        mod: 5,
        cost: 100,
        preReq: 'u01',
    },
    u03: {
        sid: 'u03',
        label: 'Goatee',
        description: 'Your mighty goatee intimidates buyers into buying more product; you can now sell an extra batch at a time',
        action: UpgradeAction.Sell,
        purchased: false,
        mod: 1,
        cost: 10,
        preReq: null,
    },
    u04: {
        sid: 'u04',
        label: 'Industrial HVAC',
        description: 'Keep the fumes out. You can now cook 100 more batches at a time',
        action: UpgradeAction.Make,
        purchased: false,
        mod: 100,
        cost: 11_050,
        preReq: 'u02',
    },
    u05: {
        sid: 'u05',
        label: 'Prescription Glasses',
        description: 'Your nerdy specs make your buyers feel they can trust you more; you can now sell 5 additional batches at a time',
        action: UpgradeAction.Sell,
        purchased: false,
        mod: 5,
        cost: 100,
        preReq: 'u03',
    },
    u06: {
        sid: 'u06',
        label: 'Porkpie Hat',
        description: 'This early 20th century hat keeps the sun out of your eyes, allowing you to make an additional 50 batches at a time',
        action: UpgradeAction.Make,
        purchased: false,
        mod: 50,
        cost: 500,
        preReq: 'u05',
    },
    u07: {
        sid: 'u07',
        label: 'Mariachi Band',
        description: 'An authentic narcocorrido band to sing the tale of you and your meth. Allows you to charge an extra $5 per batch',
        action: UpgradeAction.BatchCost,
        purchased: false,
        mod: 5,
        cost: 17_500,
        preReq: 'u06',
    },
    u08: {
        sid: 'u08',
        label: 'Brewing Vats',
        description: 'Cook your meth in massive vats like the pros. Allows you to cook an additional 500 batches at a time',
        action: UpgradeAction.Make,
        purchased: false,
        mod: 500,
        cost: 4_507_500,
        preReq: 'u07',
    },
    u09: {
        sid: 'u09',
        label: 'Dealer Business Cards',
        description: 'Improves sale rate of your dealers by .1/s',
        action: 'sellers.s01.rps',
        purchased: false,
        mod: 0.1,
        cost: 75,
        preReq: 'u03',
    },
    u10: {
        sid: 'u10',
        label: "Spinnin' Rims",
        description: 'Roll with style! Improves the sale rate of your dealers by .2/s',
        action: 'sellers.s01.rps',
        purchased: false,
        mod: 0.2,
        cost: 250,
        preReq: 'u09',
    },
    u11: {
        sid: 'u11',
        label: 'Dealer Slacks',
        description: 'Your dealers now wear nice slacks, reducing suspicion by 1%',
        action: 'sellers.s01.risk',
        purchased: false,
        mod: -0.01,
        cost: 550,
        preReq: 'u10',
    },
    u12: {
        sid: 'u12',
        label: 'Stuffed Mules',
        description: 'Your Drug Mules can now sell an additional 3 batches at a time',
        action: 'sellers.s02.rps',
        purchased: false,
        mod: 3,
        cost: 1_250,
        preReq: 'u11',
    },
    u13: {
        sid: 'u13',
        label: 'Double Stuffed Mules',
        description: 'Your Drug Mules can now sell an additional 16 batches at a time',
        action: 'sellers.s02.rps',
        purchased: false,
        mod: 16,
        cost: 55_000,
        preReq: 'u12',
    },
    u14: {
        sid: 'u14',
        label: 'Dealer Heat',
        description: "Now your dealers are packin' heat, allowing them to safely sell another half-batch at a time",
        action: 'sellers.s01.rps',
        purchased: false,
        mod: 0.5,
        cost: 8_000,
        preReq: 'u11',
    },
    u15: {
        sid: 'u15',
        label: 'Van Jingle',
        description: 'Your drug vans play a catchy jingle to attract more customers, selling 5 more batches at a time',
        action: 'sellers.s03.rps',
        purchased: false,
        mod: 5,
        cost: 16_000,
        preReq: 'u14',
    },
    u16: {
        sid: 'u16',
        label: 'Extra Sleaze',
        description: 'Your lawyers are now extra sleazy, and can sell an additional 10 batches at a time',
        action: 'sellers.s04.rps',
        purchased: false,
        mod: 10,
        cost: 150_000,
        preReq: 'u15',
    },
    u17: {
        sid: 'u17',
        label: 'Better Lawyers',
        description: 'Your sleazy lawyers now reduce risk by an additional 5%',
        action: 'sellers.s04.risk',
        purchased: false,
        mod: -0.05,
        cost: 1_175_000,
        preReq: 'u16',
    },
    u18: {
        sid: 'u18',
        label: 'Lawyers 2.0',
        description: 'Your sleazy lawyers now use the Chewbacca Defense. They reduce risk by an additional 5%',
        action: 'sellers.s04.risk',
        purchased: false,
        mod: -0.05,
        cost: 11_275_000,
        preReq: 'u17',
    },
    u19: {
        sid: 'u19',
        label: 'Super Lawyers',
        description: 'Your sleazy lawyers now wear a cape. They reduce risk by an additional 5%',
        action: 'sellers.s04.risk',
        purchased: false,
        mod: -0.05,
        cost: 210_555_000,
        preReq: 'u18',
    },
    u20: {
        sid: 'u20',
        label: 'Immortal Lawyers',
        description: 'Your lawyers are now demigods. They reduce risk by an additional 10%',
        action: 'sellers.s04.risk',
        purchased: false,
        mod: -0.10,
        cost: 164_200_552_000,
        preReq: 'u19',
    },
    u21: {
        sid: 'u21',
        label: 'Diplomatic Immunity',
        description: 'Your diplomats can now sell an extra 1K batches',
        action: 'sellers.s08.rps',
        purchased: false,
        mod: 1_000,
        cost: 15_005_000,
        preReq: 'u16',
    },
    u22: {
        sid: 'u22',
        label: 'Portable Power Generator',
        description: 'Provides extra power to your RVs - adding 0.5 production per second',
        action: 'cookers.c02.rps',
        purchased: false,
        mod: 0.5,
        cost: 150,
        preReq: 'u02',
    },
    u23: {
        sid: 'u23',
        label: 'Shed Propane',
        description: 'Outfit your Storage Sheds with propane and propane accessories. They cook another 0.8 batches at a time',
        action: 'cookers.c01.rps',
        purchased: false,
        mod: 0.8,
        cost: 9_500,
        preReq: 'u22',
    },
    u24: {
        sid: 'u24',
        label: 'RV Solar Panels',
        description: 'Harness the power of the sun! Allows your RV cooks to make an additional 2.5 batches at a time',
        action: 'cookers.c02.rps',
        purchased: false,
        mod: 2.5,
        cost: 1_250,
        preReq: 'u23',
    },
    u25: {
        sid: 'u25',
        label: 'Mobile Lab',
        description: 'Completely outfit your RVs for maximum meth production, netting you 16 extra batches at a time',
        action: 'cookers.c02.rps',
        purchased: false,
        mod: 16,
        cost: 55_000,
        preReq: 'u24',
    },
    u26: {
        sid: 'u26',
        label: 'Hazmat Suit',
        description: 'Now you can cook without regard for personal safety! Make an additional 100 batches at a time',
        action: UpgradeAction.Make,
        purchased: false,
        mod: 100,
        cost: 15_000,
        preReq: 'u05',
    },
    u27: {
        sid: 'u27',
        label: 'Personal Enforcer',
        description: 'Hire a personal enforcer to prevent your shit from getting stolen, you can now sell an extra 100 batches at a time',
        action: UpgradeAction.Sell,
        purchased: false,
        mod: 100,
        cost: 15_000,
        preReq: 'u26',
    },
    u28: {
        sid: 'u28',
        label: 'Electric Hotplate',
        description: 'Used for cooking',
        action: null,
        purchased: true,
        mod: 0,
        cost: 25,
        preReq: null,
    },
    u29: {
        sid: 'u29',
        label: 'Gas Stove',
        description: 'Improves meth purity by 0.5 IPU',
        action: UpgradeAction.BatchCost,
        purchased: false,
        mod: 0.5,
        cost: 120,
        preReq: null,
    },
    u30: {
        sid: 'u30',
        label: 'Steel Burners',
        description: 'Improves meth purity by another 0.5 IPU',
        action: UpgradeAction.BatchCost,
        purchased: false,
        mod: 0.5,
        cost: 240,
        preReq: 'u29',
    },
    u31: {
        sid: 'u31',
        label: 'Titanium Burners',
        description: 'Spaceship-grade burners improve meth purity by 1 IPU',
        action: UpgradeAction.BatchCost,
        purchased: false,
        mod: 1,
        cost: 3_550,
        preReq: 'u30',
    },
    u32: {
        sid: 'u32',
        label: 'Platinum Burners',
        description: 'Industrial grade platinum burners improve meth purity by 3 IPUs',
        action: UpgradeAction.BatchCost,
        purchased: false,
        mod: 3,
        cost: 23_550,
        preReq: 'u31',
    },
    u33: {
        sid: 'u33',
        label: 'Cheap Cookware',
        description: 'Used to cook stuff',
        action: null,
        purchased: true,
        mod: 0,
        cost: 20,
        preReq: null,
    },
    u34: {
        sid: 'u34',
        label: 'Stainless Steel Cookware',
        description: 'Improves meth purity by 0.5 IPU',
        action: UpgradeAction.BatchCost,
        purchased: false,
        mod: 0.5,
        cost: 120,
        preReq: null,
    },
    u35: {
        sid: 'u35',
        label: 'Glass Flasks',
        description: 'Further improves meth purity by another 0.5 IPU',
        action: UpgradeAction.BatchCost,
        purchased: false,
        mod: 0.5,
        cost: 240,
        preReq: 'u34',
    },
    u36: {
        sid: 'u36',
        label: 'Hardened Glass Boilers',
        description: 'Further improves meth purity by 1 IPU',
        action: UpgradeAction.BatchCost,
        purchased: false,
        mod: 1,
        cost: 1_500,
        preReq: 'u35',
    },
    u37: {
        sid: 'u37',
        label: 'Carbon Filtration',
        description: 'Filters out the extra deadly components, adding 3 IPUs',
        action: UpgradeAction.BatchCost,
        purchased: false,
        mod: 3,
        cost: 92_500,
        preReq: 'u36',
    },
    u38: {
        sid: 'u38',
        label: 'Diamond Flasks',
        description: 'Further improves purity by 5 IPUs',
        action: UpgradeAction.BatchCost,
        purchased: false,
        mod: 5,
        cost: 252_500,
        preReq: 'u36',
    },
    u39: {
        sid: 'u39',
        label: 'Platinum Boilers',
        description: 'Improves purity by 10 IPUs!',
        action: UpgradeAction.BatchCost,
        purchased: false,
        mod: 10,
        cost: 2_155_000,
        preReq: 'u38',
    },
    u40: {
        sid: 'u40',
        label: 'Space Hazmat Suit',
        description: 'Now you can cook in space! Cook an additional 1000 batches at a time',
        action: UpgradeAction.Make,
        purchased: false,
        mod: 1_000,
        cost: 121_550_000,
        preReq: 'u39',
    },
    u41: {
        sid: 'u41',
        label: 'SWAT Snipers',
        description: 'Your team of highly trained snipers protects you during high-value transactions. Safely sell an additional 1000 batches at a time',
        action: UpgradeAction.Sell,
        purchased: false,
        mod: 1_000,
        cost: 321_500_000,
        preReq: 'u39',
    },
    u42: {
        sid: 'u42',
        label: 'Chemistry Doctorate',
        description: 'By ordering this online degree, your increased confidence allows you to make another 50% of production batches at a time',
        action: UpgradeAction.MakeMultiplier,
        purchased: false,
        mod: 0.5,
        cost: 4_500_750_000,
        preReq: 'u40',
    },
    u43: {
        sid: 'u43',
        label: 'Mech Suit',
        description: 'Your mech suit keeps you safe while making deals. Sell an additional 50% of sales batches at a time',
        action: UpgradeAction.SellMultiplier,
        purchased: false,
        mod: 0.5,
        cost: 5_100_500_000,
        preReq: 'u41',
    },
    u44: {
        sid: 'u44',
        label: 'Slap Chop (TM)',
        description: "You're gonna love my meth. With this precision cutting device, you can now make an additional 50% of production at a time",
        action: UpgradeAction.MakeMultiplier,
        purchased: false,
        mod: 0.5,
        cost: 18_100_500_000,
        preReq: 'u42',
    },
    u45: {
        sid: 'u45',
        label: 'Fearless',
        description: 'After facing death so many times, you no longer have a sense of self preservation. Sell another 50% of sales at a time',
        action: UpgradeAction.SellMultiplier,
        purchased: false,
        mod: 0.5,
        cost: 20_100_500_000,
        preReq: 'u43',
    },
    u46: {
        sid: 'u46',
        label: 'Pinky Genetics',
        description: 'Hire every scientist on the planet to enhance your finger dexterity. Manually cook an additional 100% of production at a time',
        action: UpgradeAction.MakeMultiplier,
        purchased: false,
        mod: 1,
        cost: 3_518_100_500_000,
        preReq: 'u44',
    },
    u47: {
        sid: 'u47',
        label: 'Methbie',
        description: "After being bitten by a radioactive crack head, you've gained super strength. Manually sell an additional 100% of sales at a time",
        action: UpgradeAction.SellMultiplier,
        purchased: false,
        mod: 1,
        cost: 3_720_100_500_000,
        preReq: 'u45',
    },
    u48: {
        sid: 'u48',
        label: 'Trick or Treat',
        description: "Hand out meth like it's candy, and sell an additional 200% of sales at a time",
        action: UpgradeAction.SellMultiplier,
        purchased: false,
        mod: 2,
        cost: 15_000_000,
        preReq: null,
        hidden: true,
    },
    u49: {
        sid: 'u49',
        label: 'Ancient Methology',
        description: 'Send your best scientists to remote parts of the world looking for lost secrets of meth production, increase purity by 10 IPUs',
        action: UpgradeAction.BatchCost,
        purchased: false,
        mod: 10,
        cost: 820_000_000,
        preReq: 'u39',
    },
    u50: {
        sid: 'u50',
        label: 'Methylamine X',
        description: 'By unlocking this experimental methylamine-based compound you increase purity by 10 IPUs',
        action: UpgradeAction.BatchCost,
        purchased: false,
        mod: 10,
        cost: 126_321_500_000,
        preReq: 'u49',
    },
    u51: {
        sid: 'u51',
        label: 'Meth of the Third Kind',
        description: 'Extra terrestrial meth purification processes have been discovered on Mars. Increases purity by another 12 IPUs',
        action: UpgradeAction.BatchCost,
        purchased: false,
        mod: 12,
        cost: 2_100_320_555_000,
        preReq: 'u50',
    },
    u52: {
        sid: 'u52',
        label: 'Quantum Meth',
        description: 'This meth is atomically perfect, allowing you to charge an additional $45 per batch',
        action: UpgradeAction.BatchCost,
        purchased: false,
        mod: 44.5,
        cost: 42_100_500_555_000,
        preReq: 'u51',
    },
    u53: {
        sid: 'u53',
        label: 'Holy Meth',
        description: 'Your followers feel closer to the heavens when they gaze upon these holy crystals. Charge another $53 per batch',
        action: UpgradeAction.BatchCost,
        purchased: false,
        mod: 53,
        cost: 142_100_500_555_000,
        preReq: 'u51',
    },
    u54: {
        sid: 'u54',
        label: 'Angelic Meth',
        description: 'It is said that the Arch Angels of Methen prefer the taste of your meth',
        action: UpgradeAction.BatchCost,
        purchased: false,
        mod: 60,
        cost: 1_042_100_500_555_000,
        preReq: 'u53',
    },
    // Laundering
    u55: {
        sid: 'u55',
        label: 'Insider Trading',
        description: 'Improves NYME laundering by $500m per second ',
        action: 'banks.b09.rps',
        purchased: false,
        mod: 500_000_000,
        cost: 50_020_555_000,
        preReq: 'u16',
    },
    u56: {
        sid: 'u56',
        label: 'Sleazy Brokers',
        description: 'These sleazy stock brokers help your NYME launder an extra $1B per second',
        action: 'banks.b09.rps',
        purchased: false,
        mod: 1_000_000_000,
        cost: 500_320_555_000,
        preReq: 'u55',
    },
    u57: {
        sid: 'u57',
        label: 'Fortune 500 Franchise',
        description: 'Each franchise can now launder an extra $2B per second',
        action: 'banks.b10.rps',
        purchased: false,
        mod: 2_000_000_000,
        cost: 590_000_555_000,
        preReq: 'u56',
    },
    u58: {
        sid: 'u58',
        label: 'Cantina Band',
        description: 'Increase Cantina laundering speed by $5B with live flute music ',
        action: 'banks.b11.rps',
        purchased: false,
        mod: 5_000_000_000,
        cost: 1_590_000_555_000,
        preReq: 'u57',
    },
    u59: {
        sid: 'u59',
        label: 'Space Capitalism',
        description: "Increase Space Corp's laundering rate by $0.2T",
        action: 'banks.b13.rps',
        purchased: false,
        mod: 200_000_000_000,
        cost: 2_500_000_555_000,
        preReq: 'u58',
    },
    u70: {
        sid: 'u70',
        label: 'Thank You',
        description: 'Thanks for donating, your meth is now worth $50 more per batch',
        action: UpgradeAction.BatchCost,
        purchased: false,
        mod: 50,
        cost: 321_500_000,
        preReq: null,
        hidden: true,
    },
}
